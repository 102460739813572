import * as React from "react"

import Layout from "../components/Layout"
import Header from "../components/Header";
import { MainContainer } from "../containers/MainContainer";

const PageTemplate = (props) => {
  
  return (
    <Layout>
      <Header slug={props.pageContext.slug} />
      <MainContainer className={`${props.pageContext.slug === 'contact' ? 'page contact' : 'page'}`}>
        <div dangerouslySetInnerHTML={{__html: props.pageContext.data.content.html}}></div>
      </MainContainer>
    </Layout>
  )
}

export default PageTemplate
